html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0px;
}

.App {
  text-align: center;
  background-image: url("./images/bg2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.navbar {
  background-color: rgba(210, 215, 211, 0.8);
  display: flex;
}

.navbar-scroll {
  background-color: rgba(210, 215, 211, 1);
}

.navbar-item {
  padding: 10px;
  margin: auto
}

.footer {
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  margin-top: 2rem;
}

.main-container {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
}

.home-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* ADMIN PANEL */
.admin-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.user-list {
  flex-grow: 1;
  display: block;
  text-align: left;
  padding: 5px;
  height: fit-content;
}

.request-panel {
  flex-grow: 1;
  display: block;
  text-align: left;
  padding: 5px;
  height: fit-content;
}

.video-panel {
  flex-grow: 1;
  display: block;
  text-align: left;
  padding: 5px;
  height: fit-content;
}

.previews-container {
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  justify-content: center;
  align-content: flex-start;
  flex-grow: 4;
  margin: 0;
}

.previews-container-plus-button {
  display: flex;
  padding: 10px;
  width: 60%;
  justify-content: center;
  align-content: flex-start;
  flex-grow: 4;
  margin: 0;
}

.previews-card-container {
  padding: 5px;
  margin: 2px;
  width: 14rem;
  height: fit-content;
}

.card-img-top {
  width: fit-content;
}

.playlists-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  flex-grow: 1;
  margin-right: 2rem;
  margin-left: 2rem;
}

.playlists {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 4;
  width: 100%;
  margin-right: 0rem;
}

.player-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.video-container {
  display: flex;
  justify-content: center;
  flex-grow: 4;
  max-width: 70%;
  padding: 10px;
  color: white;
}

.scroll-down { 
  display: none;
}

@media only screen and (max-width: 1180px) {
  .scroll-down {
    display: block;
  }
  .home-container {
    display: block;
  }
  .previews-container-plus-button {
    width: 100%;
  } 
}


.video-description {
  text-align: left;
  color: white;
}

.password-icon {
  position: relative;
  top: 20;
  left: 10;
}

.creator-panel {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
  margin: 0;
}

.content-list {
  flex-grow: 1;
  display: block;
  overflow: auto;
  height: auto;
}

.add-content {
  display: block;
  flex-grow: 1;
  height: fit-content;
}

.registration-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 95%;
  padding: 10px; 
}

.registration-info {
  display: flex;
  justify-content: center;
  margin: auto;
  flex-grow: 1;
}

.btneffect:active {
  transform: scaleY(1.3) scaleX(1.3);
}